export const colors = [
	'#f58220',
	'#fcc195',
	'#86BBD8',
	'#7CFFCB',
	'#E0B1CB',
	'#AF8D86',
	'#C14953',
	'#5ad45a',
	'#A06B9A',
];
