import { Box, Button, CircularProgress, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorDialog, EstablishmentAppBar } from '../../shared/components';
import { QuantityManager } from '../../shared/components/QuantityManager';
import { ReloadScreen } from '../../shared/components/ReloadScreen';
import { useEstablishment } from '../../shared/contexts';
import { useBag } from '../../shared/contexts/BagContext';
import { OrderItem } from '../../shared/entities';
import { SelectRequiredAdditionalsError } from '../../shared/errors';
import { useProduct } from './ProductContext';
import { ObservationInput } from './components/ObservationInput';
import { ProductComponent } from './components/Product';

export function ProductPage() {
	const id = useParams().id as unknown as number;
	const { establishment, config } = useEstablishment().state;
	const navigate = useNavigate();
	const { addItem } = useBag();
	const {
		product,
		isLoading,
		error,
		quantity,
		totalPrice,
		observations,
		dialogError,
	} = useProduct().state;
	const {
		loadProduct,
		onObservationChange,
		incrementQuantity,
		decrementQuantity,
		selectedAdditionals,
		requiredAdditionalsSelected,
		closeDialogError,
		setDialogError,
	} = useProduct();

	function addProductToBag() {
		if (!requiredAdditionalsSelected()) {
			return setDialogError(new SelectRequiredAdditionalsError());
		}
		const item = new OrderItem(
			product!.id,
			product!.name,
			product!.price,
			quantity,
			observations,
			establishment.isSelfService ? config.tipPercent : 0,
			selectedAdditionals(),
			totalPrice
		);
		addItem(item);
		navigate(-1);
	}

	useEffect(() => {
		loadProduct(id);
	}, []);

	return (
		<>
			<EstablishmentAppBar />
			{(() => {
				if (isLoading) {
					return (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100vh',
							}}
						>
							<CircularProgress disableShrink />
						</div>
					);
				} else if (error != null) {
					return (
						<ReloadScreen
							message={error.message}
							onReload={() => loadProduct(id)}
						/>
					);
				}

				return (
					<div style={{ marginBottom: '100px' }}>
						<ProductComponent product={product!} />
						{product!.additionalGroups.length > 0 && (
							<Divider style={{ marginBottom: '30px' }} />
						)}
						<ObservationInput
							value={observations}
							onChange={onObservationChange}
						/>
						{product!.additionalGroups.length > 0 && (
							<Divider style={{ marginTop: '30px' }} />
						)}
						<Box
							sx={{
								position: 'fixed',
								bottom: 0,
								left: 0,
								right: 0,
							}}
							height="60px"
							bgcolor="grey.100"
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							padding="0 20px"
						>
							<QuantityManager
								quantity={quantity}
								onIncrement={incrementQuantity}
								onDecrement={decrementQuantity}
								decrementEnabled={quantity > 1}
							/>
							<Button
								variant="contained"
								disableElevation
								onClick={addProductToBag}
							>
								Adicionar | {totalPrice.toCurrency()}
							</Button>
						</Box>
					</div>
				);
			})()}
			<ErrorDialog
				error={dialogError?.message}
				open={dialogError != null}
				close={closeDialogError}
			/>
		</>
	);
}
