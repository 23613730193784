import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import {
	Additional,
	AdditionalGroup,
	OrderItemAdditional,
	Product,
} from '../../shared/entities';
import { AppError } from '../../shared/errors';
import { ProductRepository } from '../../shared/repositories';

type Props = {
	children: ReactNode;
	productRepository: ProductRepository;
};

type PropsProductContext = {
	state: {
		product: Product | null;
		isLoading: boolean;
		error: AppError | null;
		dialogError: AppError | null;
		quantity: number;
		observations: string;
		totalPrice: number;
		additionals: Map<Additional, number>;
	};
	loadProduct: (id: number) => void;
	incrementQuantity: () => void;
	decrementQuantity: () => void;
	onObservationChange: (value: string) => void;
	incrementAdditionalQuantity: (additional: Additional) => void;
	decrementAdditionalQuantity: (additional: Additional) => void;
	selectAdditional: (additional: Additional) => void;
	totalAdditionalGroupSelected: (group: AdditionalGroup) => number;
	selectedAdditionals: () => OrderItemAdditional[];
	limitAdditionalReached: (additional: Additional) => boolean;
	requiredAdditionalsSelected: () => boolean;
	closeDialogError: () => void;
	setDialogError: (error: AppError) => void;
};

const DEFAULT_VALUE = {
	state: {
		product: null as Product | null,
		isLoading: true,
		error: null as AppError | null,
		dialogError: null as AppError | null,
		quantity: 1,
		observations: '',
		totalPrice: 0,
		additionals: new Map<Additional, number>(),
	},
	loadProduct: (id: number) => {},
	incrementQuantity: () => {},
	decrementQuantity: () => {},
	onObservationChange: (value: string) => {},
	decrementAdditionalQuantity: (additional: Additional) => {},
	incrementAdditionalQuantity: (additional: Additional) => {},
	selectAdditional: (additional: Additional) => {},
	totalAdditionalGroupSelected: (group: AdditionalGroup) => 0,
	selectedAdditionals: () => [] as OrderItemAdditional[],
	limitAdditionalReached: (additional: Additional) => false,
	requiredAdditionalsSelected: () => false,
	closeDialogError: () => {},
	setDialogError: (error: AppError) => {},
};

export const ProductContext = createContext<PropsProductContext>(DEFAULT_VALUE);

export const useProduct = (): PropsProductContext => useContext(ProductContext);

export const ProductContextProvider = ({
	children,
	productRepository,
}: Props) => {
	const [state, setState] = useState(DEFAULT_VALUE.state);

	async function loadProduct(id: number) {
		setState({ ...state, error: null, isLoading: true });
		try {
			const product = await productRepository.getProduct(id);
			setState({
				...state,
				product,
				isLoading: false,
				totalPrice: product.price,
			});
		} catch (error) {
			setState({ ...state, error: error as AppError, isLoading: false });
		}
	}

	useEffect(() => {
		if (!state.product) return;
		const additionals = selectedAdditionals();
		const additionalsTotal = Array.from(additionals).reduce(
			(acc, item) => acc + item.total,
			0
		);
		const total = (state.product!.price + additionalsTotal) * state.quantity;
		setState({ ...state, totalPrice: total });
	}, [state.quantity, state.additionals]);

	useEffect(() => {
		return () => {
			state.additionals.clear();
		};
	}, []);

	function incrementQuantity(): void {
		if (state.quantity === 99) return;
		const quantity = state.quantity + 1;
		setState({ ...state, quantity });
	}

	function decrementQuantity(): void {
		if (state.quantity === 1) return;
		const quantity = state.quantity - 1;
		setState({ ...state, quantity });
	}

	function onObservationChange(value: string): void {
		setState({ ...state, observations: value });
	}

	function limitAdditionalReached(additional: Additional) {
		const group = state.product!.additionalGroups.find(
			(group) => group.id === additional.groupId
		)!;
		if (!group.repeatable && state.additionals.getOr(additional, 0) > 0) {
			return true;
		}
		return totalAdditionalGroupSelected(group) >= group.max;
	}

	function incrementAdditionalQuantity(additional: Additional) {
		const group = state.product!.additionalGroups.find(
			(group) => group.id === additional.groupId
		)!;
		const current = state.additionals.getOr(additional, 0);
		if (current >= group.max || limitAdditionalReached(additional)) return;
		const additionals = state.additionals.set(additional, current + 1);
		setState({ ...state, additionals: new Map(additionals) });
	}

	function decrementAdditionalQuantity(additional: Additional) {
		const current = state.additionals.get(additional);
		if (!current) return;
		if (current === 1) {
			state.additionals.delete(additional);
			return setState({ ...state, additionals: new Map(state.additionals) });
		}
		const additionals = state.additionals.set(additional, current - 1);
		setState({ ...state, additionals: new Map(additionals) });
	}

	function selectAdditional(additional: Additional) {
		const isSelected = state.additionals.get(additional) === 1;
		for (const entry of Array.from(state.additionals.keys())) {
			if (entry.groupId === additional.groupId) {
				state.additionals.set(entry, 0);
			}
		}
		state.additionals.set(additional, isSelected ? 0 : 1);
		setState({ ...state, additionals: new Map(state.additionals) });
	}

	function totalAdditionalGroupSelected(group: AdditionalGroup): number {
		const additionals = Array.from(state.additionals).filter(
			([additional]) => additional.groupId === group.id
		);
		return additionals.reduce((acc, [, quantity]) => acc + quantity, 0);
	}

	function selectedAdditionals(): OrderItemAdditional[] {
		const additionals = Array.from(state.additionals).filter(
			([, quantity]) => quantity > 0
		);
		const grouped = [] as {
			group: AdditionalGroup;
			additionals: OrderItemAdditional[];
		}[];
		for (const [additional, quantity] of additionals) {
			const group = state.product!.additionalGroups.find(
				(group) => group.id === additional.groupId
			)!;
			const index = grouped.findIndex((item) => item.group.id === group.id);
			const orderItemAdditional = new OrderItemAdditional(
				additional.id,
				additional.groupId,
				additional.name,
				additional.price,
				quantity,
				additional.price * quantity,
				additional.position
			);
			if (index >= 0) {
				grouped[index].additionals.push(orderItemAdditional);
				grouped[index].additionals = grouped[index].additionals.sort(
					(a, b) => a.position - b.position
				);
			} else {
				grouped.push({ group, additionals: [orderItemAdditional] });
			}
		}
		return grouped
			.sort((a, b) => a.group.position - b.group.position)
			.map((item) => item.additionals)
			.flat();
	}

	function requiredAdditionalsSelected(): boolean {
		return state
			.product!.additionalGroups.filter((group) => group.required)
			.every((group) => totalAdditionalGroupSelected(group) >= group.min);
	}

	function closeDialogError() {
		setState({ ...state, dialogError: null });
	}

	function setDialogError(error: AppError) {
		setState({ ...state, dialogError: error });
	}

	return (
		<ProductContext.Provider
			value={{
				state,
				loadProduct,
				incrementQuantity,
				decrementQuantity,
				onObservationChange,
				incrementAdditionalQuantity,
				decrementAdditionalQuantity,
				selectAdditional,
				totalAdditionalGroupSelected,
				selectedAdditionals,
				limitAdditionalReached,
				requiredAdditionalsSelected,
				closeDialogError,
				setDialogError,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};
