import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Fab } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../../shared/components/Loading';
import { ReloadScreen } from '../../shared/components/ReloadScreen';
import { useEstablishment } from '../../shared/contexts';
import { useBag } from '../../shared/contexts/BagContext';
import { Group, Product } from '../../shared/entities';
import { AppError } from '../../shared/errors';
import { useDidUpdate } from '../../shared/hooks/did_update';
import { ProductRepository } from '../../shared/repositories';
import { BagCard } from './components/BagCard';
import { EstablishmentClosedDialog } from './components/EstablishmentClosedDialog';
import { GroupProducts } from './components/GroupProducts';
import { Groups } from './components/Groups';
import { NoProductsFound } from './components/NoProductsFound';
import { Products } from './components/Products';
import { SearchField } from './components/SearchField';
import { pageSize } from './constants';

type Props = {
	productRepository: ProductRepository;
};

export function MenuPage({ productRepository }: Props) {
	const { state } = useBag();
	const { establishment, establishmentClosed } = useEstablishment().state;
	const navigate = useNavigate();
	const { hash, key } = useLocation();
	const [groups, setGroups] = useState<Group[]>([]);
	const [products, setProducts] = useState<Product[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
	const [selectedSubGroup, setSelectedSubGroup] = useState<Group | null>(null);
	const prevSelectedSubGroupRef = useRef<Group | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [menuError, setMenuError] = useState<AppError | null>(null);
	const [page, setPage] = useState({ value: 1 }); // Força o trigger do useEffect mesmo quando o valor não muda
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [closedEstablishmentDialogOpen, setClosedEstablishmentDialogOpen] =
		useState(false);

	function loadMenu() {
		setMenuError(null);
		setIsLoading(true);
		Promise.all([productRepository.getGroups().then(setGroups), loadProducts()])
			.catch((error) => setMenuError(error as AppError))
			.finally(() => setIsLoading(false));
	}

	async function loadProducts() {
		await productRepository
			.getProducts({
				text: searchTerm,
				page: page.value,
				size: pageSize,
			})
			.then((newProducts) => {
				setHasMore(newProducts.length >= pageSize);
				if (page.value === 1) {
					setProducts(newProducts);
				} else {
					setProducts([...products, ...newProducts]);
				}
			});
	}

	useDidUpdate(() => {
		setIsLoading(true);
		loadProducts().finally(() => setIsLoading(false));
	}, [page]);

	function loadMore() {
		setPage({ value: page.value + 1 });
	}

	function selectGroup(group: Group | null) {
		if (group?.isPizza) {
			if (establishmentClosed) {
				return setClosedEstablishmentDialogOpen(true);
			}
			return navigate(`/pizza`);
		}
		setSelectedGroup(group);
		if (group) {
			const element = document.getElementById(group.archorLink);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}

	useEffect(() => {
		loadMenu();
	}, []);

	useEffect(() => {
		if (selectedSubGroup == null && prevSelectedSubGroupRef.current != null) {
			setTimeout(() => scrollToTop(), 50);
		}
		prevSelectedSubGroupRef.current = selectedSubGroup;
	}, [selectedSubGroup]);

	function scrollToTop() {
		const targetElement = document.getElementsByTagName('html')[0];
		let position = targetElement?.getBoundingClientRect();
		if (!position) return;
		window.scrollBy({ behavior: 'smooth', top: position.top });
	}

	useDidUpdate(() => {
		if (!selectedGroup) {
			setPage({ value: 1 });
		}
	}, [searchTerm]);

	useEffect(() => {
		if (hash) {
			const targetElement = document.getElementById(hash.substring(1));
			let position = targetElement?.getBoundingClientRect();
			if (!position) return;
			window.scrollBy({ behavior: 'smooth', top: position.top - 250 });
		}
	}, [key, hash]);

	function openWhatsApp() {
		const url = `https://wa.me/${establishment.phone}`;
		window.open(url, '_blank');
	}

	const isInitialLoading = isLoading && !groups.length && !products.length;

	if (isInitialLoading) {
		return <Loading />;
	} else if (menuError != null) {
		return <ReloadScreen message={menuError.message} onReload={loadMenu} />;
	}

	return (
		<Box paddingBottom={20}>
			<EstablishmentClosedDialog
				open={closedEstablishmentDialogOpen}
				close={() => setClosedEstablishmentDialogOpen(false)}
			/>
			<div
				style={{
					padding: '8px 20px 0 20px',
					position: 'fixed',
					width: 'calc(100% - 40px)',
					backgroundColor: 'white',
					zIndex: 1,
				}}
			>
				<SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
				<Groups
					groups={groups}
					selectedGroup={selectedGroup}
					selectedSubGroup={selectedSubGroup}
					onGroupPressed={selectGroup}
					onSubGroupPressed={setSelectedSubGroup}
				/>
			</div>

			<Box
				top={
					selectedGroup && selectedGroup.subgroups.length > 0
						? '160px'
						: '120px'
				}
				position="relative"
			>
				{(() => {
					if (!products && searchTerm) {
						return <NoProductsFound />;
					}

					if (isLoading && groups.length && page.value === 1) {
						return <Loading />;
					}

					if (products.length === 0) {
						return <NoProductsFound />;
					}

					let mainContent;
					if (selectedGroup == null) {
						mainContent = (
							<Products
								products={products}
								hasMore={hasMore}
								isLoading={isLoading}
								loadMore={loadMore}
							/>
						);
					} else {
						mainContent = (
							<>
								{[selectedGroup, ...selectedGroup.subgroups].map((group) => (
									<GroupProducts
										key={`group+${group.id}`}
										productRepository={productRepository}
										search={searchTerm}
										group={group}
									/>
								))}
							</>
						);
					}

					return mainContent;
				})()}
				{!state.isEmpty && <BagCard />}
			</Box>
			{establishment.isDelivery && establishment.phone && (
				<Fab
					onClick={openWhatsApp}
					sx={{
						position: 'fixed',
						bottom: state.isEmpty ? 16 : 70,
						right: 16,
						backgroundColor: '#075E54',
						color: '#FFF',
						'&:hover': {
							backgroundColor: '#128C7E',
						},
					}}
				>
					<WhatsAppIcon />
				</Fab>
			)}
		</Box>
	);
}
