import { ListItem, ListItemButton, Radio, Typography } from '@mui/material';
import { QuantityManager } from '../../../shared/components';
import { Additional, AdditionalGroup } from '../../../shared/entities';
import { usePizza } from '../PizzaContext';

type Props = {
	additional: Additional;
	group: AdditionalGroup;
};

export function AdditionalTile({ additional, group }: Props) {
	const { selectAdditional } = usePizza();

	return (
		<ListItem
			key={additional.id}
			onClick={() => {
				if (!group.isSingleChoice) return;
				selectAdditional(additional);
			}}
			secondaryAction={
				group.isSingleChoice ? (
					<AdditionalRadio additional={additional} />
				) : (
					<AdditionalQuantifier additional={additional} group={group} />
				)
			}
			disablePadding
		>
			<ListItemButton
				sx={{
					'&:hover': { backgroundColor: 'transparent' },
				}}
				disableTouchRipple
			>
				<div>
					<Typography variant="subtitle2" paddingRight={12}>
						{additional.name}
					</Typography>
					<Typography variant="subtitle2" fontWeight="bold">
						{additional.price.toCurrency()}
					</Typography>
					{additional.description && (
						<Typography
							variant="body2"
							color="text.secondary"
							paddingRight={12}
						>
							{additional.description}
						</Typography>
					)}
				</div>
			</ListItemButton>
		</ListItem>
	);
}

function AdditionalQuantifier({
	additional,
	group,
}: {
	additional: Additional;
	group: AdditionalGroup;
}) {
	const {
		incrementAdditionalQuantity,
		decrementAdditionalQuantity,
		limitFlavorsReached,
		limitAdditionalsReached,
	} = usePizza();
	const quantity = usePizza().state.additionals.get(additional) || 0;

	function limitReached(): boolean {
		return group.isFlavor
			? limitFlavorsReached()
			: limitAdditionalsReached(additional);
	}

	return (
		<QuantityManager
			quantity={quantity}
			onIncrement={() => incrementAdditionalQuantity(additional)}
			onDecrement={() => decrementAdditionalQuantity(additional)}
			incrementEnabled={!limitReached()}
			decrementEnabled={quantity > 0}
		/>
	);
}

function AdditionalRadio({ additional }: { additional: Additional }) {
	const checked = usePizza().state.additionals.get(additional) === 1;
	return <Radio value={additional} checked={checked} />;
}
