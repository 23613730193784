import { Chip, List, ListItem } from '@mui/material';
import { Box } from '@mui/system';
import { Group } from '../../../shared/entities';
import { theme } from '../../../shared/theme';

export function Groups({
	groups,
	selectedGroup,
	selectedSubGroup,
	onGroupPressed,
	onSubGroupPressed,
}: {
	groups: Group[];
	selectedGroup: Group | null;
	selectedSubGroup: Group | null;
	onGroupPressed: (group: Group | null) => void;
	onSubGroupPressed: (group: Group | null) => void;
}) {
	return (
		<List
			style={{
				display: 'flex',
				flexDirection: 'row',
				padding: 0,
				top: 0,
				position: 'sticky',
				overflow: 'auto',
			}}
		>
			<Box sx={{ width: '100%' }}>
				<List style={{ display: 'flex', flexDirection: 'row' }}>
					{groups.map((group) =>
						GroupTile({
							group,
							selected: selectedGroup,
							onPressed: onGroupPressed,
						})
					)}
				</List>
				{selectedGroup && selectedGroup.subgroups.length > 0 && (
					<List
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginTop: '-12px',
							paddingLeft: '25px',
						}}
					>
						{selectedGroup.subgroups.map((group) =>
							GroupTile({
								group,
								selected: selectedSubGroup,
								onPressed: onSubGroupPressed,
							})
						)}
					</List>
				)}
			</Box>
		</List>
	);
}

function GroupTile({
	group,
	selected,
	onPressed,
}: {
	group: Group;
	selected: Group | null;
	onPressed: (group: Group | null) => void;
}) {
	const isSelected = group.id === selected?.id;
	return (
		<ListItem key={group.id} style={{ width: 'auto' }}>
			<Chip
				key={group.id}
				label={group.name}
				variant="outlined"
				onClick={() => onPressed(isSelected ? null : group)}
				style={{
					margin: '0 0px',
					padding: '0px',
					color: isSelected ? '#fff' : theme.palette.primary.main,
					fontWeight: '600',
					minWidth: '100px',
					backgroundColor: isSelected ? theme.palette.primary.main : '#fff',
					borderColor: isSelected
						? theme.palette.primary.main
						: theme.palette.divider,
				}}
			/>
		</ListItem>
	);
}
