import { Route, Routes } from 'react-router-dom';
import { AuthPage } from '../features/auth';
import { BagPage } from '../features/bag';
import { ConfirmationPage } from '../features/bag/confirmation';
import { CustomerDocumentPage } from '../features/bag/customer-document';
import { DeliveryAddressPage } from '../features/bag/delivery-address';
import { DeliveryAddressContextProvider } from '../features/bag/delivery-address/contexts/DeliveryAddressContext';
import { CreateDeliveryAddressPage } from '../features/bag/delivery-address/create';
import { EditDeliveryAddressPage } from '../features/bag/delivery-address/edit';
import { DeliveryAddressesPage } from '../features/bag/delivery-address/list';
import { DeliveryMethodPage } from '../features/bag/delivery-method';
import { PaymentChangePage } from '../features/bag/payment/payment-change';
import { PaymentMethodPage } from '../features/bag/payment/payment-method';
import { HomePage } from '../features/home';
import { PizzaPage } from '../features/pizza';
import { PizzaContextProvider } from '../features/pizza/PizzaContext';
import { ProductPage } from '../features/product';
import { ProductContextProvider } from '../features/product/ProductContext';
import { ProfilePage } from '../features/profile';
import {
	addressRepository,
	authRepository,
	customerRepository,
	establishmentRepository,
	orderRepository,
	productRepository,
	userRepository,
	waiterRepository,
} from './dependencies';

export const AppRoutes = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<HomePage
						productRepository={productRepository}
						waiterRepository={waiterRepository}
					/>
				}
			/>
			<Route
				path="/products/:id"
				element={
					<ProductContextProvider productRepository={productRepository}>
						<ProductPage />
					</ProductContextProvider>
				}
			/>
			<Route
				path="/pizza"
				element={
					<PizzaContextProvider productRepository={productRepository}>
						<PizzaPage />
					</PizzaContextProvider>
				}
			/>
			<Route path="/bag" element={<BagPage />} />
			<Route
				path="/auth"
				element={<AuthPage authRepository={authRepository} />}
			/>
			<Route
				path="/profile"
				element={<ProfilePage userRepository={userRepository} />}
			/>
			<Route path="/bag/delivery-method" element={<DeliveryMethodPage />} />
			<Route
				path="/bag/delivery-address"
				element={
					<DeliveryAddressPage
						customerRepository={customerRepository}
						establishmentRepository={establishmentRepository}
					/>
				}
			/>
			<Route
				path="/bag/delivery-address/list"
				element={
					<DeliveryAddressesPage
						customerRepository={customerRepository}
						establishmentRepository={establishmentRepository}
					/>
				}
			/>
			<Route
				path="/bag/delivery-address/create"
				element={
					<DeliveryAddressContextProvider
						establishmentRepository={establishmentRepository}
						addressRepository={addressRepository}
					>
						<CreateDeliveryAddressPage />
					</DeliveryAddressContextProvider>
				}
			/>
			<Route
				path="/bag/delivery-address/:id/edit"
				element={
					<DeliveryAddressContextProvider
						establishmentRepository={establishmentRepository}
						addressRepository={addressRepository}
					>
						<EditDeliveryAddressPage />
					</DeliveryAddressContextProvider>
				}
			/>
			<Route path="/bag/payment" element={<PaymentMethodPage />} />
			<Route path="/bag/payment/change" element={<PaymentChangePage />} />
			<Route path="/bag/customer-document" element={<CustomerDocumentPage />} />
			<Route
				path="/bag/confirm"
				element={<ConfirmationPage orderRepository={orderRepository} />}
			/>
		</Routes>
	);
};
