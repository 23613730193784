import { Product } from './product';

export class Pizza {
	constructor(public sizes: PizzaSize[]) {}

	static fromJson(json: any): Pizza {
		return new Pizza(
			(json.sizes ?? []).map((item: any) => PizzaSize.fromJson(item))
		);
	}
}

export class PizzaSize {
	id: number;
	size: string;
	description: string;
	flavorsQtt: number;
	product: Product;

	static fromJson(json: any): PizzaSize {
		return {
			id: json.id,
			size: json.size,
			description: json.description,
			flavorsQtt: json.flavors,
			product: Product.fromJson(json.product),
		};
	}
}
