import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ConfirmationDialog,
	ErrorDialog,
	EstablishmentAppBar,
	PrimaryButton,
	SuccessCard,
} from '../../../shared/components';
import { NameInput } from '../../../shared/components/NameInput';
import { useBag, useEstablishment } from '../../../shared/contexts';
import { useNotification } from '../../../shared/contexts/NotificationContext';
import {
	BlockedCustomerError,
	EstablishmentClosedError,
	SessionExpiredError,
	UnexpectedError,
} from '../../../shared/errors';
import { OrderRepository } from '../../../shared/repositories';
import { Validator } from '../../../shared/validator';
import {
	DeliveryType,
	OrderCreatedInfo,
	PaymentMethod,
	Products,
	Trailer,
} from '../components';
import { AddMoreItemsButton } from '../components/AddMoreItemsButton';
import { Customer } from '../components/Customer';
import { EmptyBag } from '../components/EmptyBag';

type Props = {
	orderRepository: OrderRepository;
};

export function ConfirmationPage({ orderRepository }: Props) {
	const { state: bagState, clearBag } = useBag();
	const { enableNotifications, state: notificationState } = useNotification();
	const { setSessionAsExpired } = useEstablishment();
	const { establishment } = useEstablishment().state;
	const navigate = useNavigate();
	const [name, setName] = useState({ value: '', error: '' });
	const [submitStatus, setSubmitStatus] = useState<
		'idle' | 'submitting' | 'submitted' | 'error'
	>('idle');
	const [error, setError] = useState<string | null>(null);
	const nameValidator = new Validator().required().minLength(3);
	const [isNotificationDialogOpen, setIsNotificationDialogOpen] =
		useState(false);
	const [canRequestNotification, setCanRequestNotification] = useState(
		establishment.isDelivery && notificationState.isNotificationFeatureEnabled
	);

	useEffect(() => {
		if (establishment.isSelfService) {
			const jsonStr = localStorage.getItem('user');
			if (!jsonStr) return;
			const user = JSON.parse(jsonStr);
			onNameChanged(user.name);
		}
	}, []);

	const goBack = () => navigate('/');

	function onNameChanged(value: string) {
		setName({ value, error: nameValidator.execute(value) });
	}

	function validate() {
		if (establishment.isSelfService) {
			const nameError = nameValidator.execute(name.value);
			setName({ ...name, error: nameError });
			return !nameError.length;
		}
		return true;
	}

	async function _sendOrder() {
		let customer = undefined;
		if (!validate()) return;
		if (establishment.isSelfService) {
			customer = { name: name.value };
			localStorage.setItem('user', JSON.stringify(customer));
		}
		if (establishment.isDelivery) {
			customer = { document: bagState.customerDocument?.onlyNumbers() };
		}
		if (canRequestNotification && notificationState.permission === 'default') {
			setIsNotificationDialogOpen(true);
			setCanRequestNotification(false);
			return;
		}
		setSubmitStatus('submitting');
		setError(null);
		orderRepository
			.sendOrder({
				customer: {
					...customer,
					deliveryAddresses: bagState.pendingDeliveryAddresses,
				},
				type: establishment.isDelivery ? 'delivery' : 'table',
				deliveryMethod: bagState.deliveryMethod,
				paymentMethod: bagState.paymentMethod,
				deliveryAddress: bagState.deliveryAddress,
				deliveryFee: bagState.deliveryFee!,
				valueForChange: bagState.changeFor,
				items: bagState.items,
			})
			.then((_) => setSubmitStatus('submitted'))
			.catch((error) => {
				if (error instanceof SessionExpiredError) return setSessionAsExpired();
				setSubmitStatus('error');
				if (error instanceof UnexpectedError) {
					setError(error.message);
				}
				if (
					error instanceof EstablishmentClosedError ||
					error instanceof BlockedCustomerError
				) {
					setError(error.description);
				}
			});
	}

	useEffect(() => {
		return () => {
			if (submitStatus !== 'submitted') return;
			clearBag();
		};
	}, [submitStatus]);

	async function onConfirmNotification() {
		setSubmitStatus('submitting');
		setError(null);
		await enableNotifications();
		_sendOrder();
	}

	function openWhatsApp() {
		const url = `https://wa.me/${establishment.phone}`;
		window.open(url, '_blank');
	}

	return (
		<>
			<ConfirmationDialog
				title="Notificações"
				body="Ative as notificações para acompanhar o andamento do seu pedido."
				confirmText="Ativar"
				onConfirm={onConfirmNotification}
				onCancel={_sendOrder}
				setOpen={() => setIsNotificationDialogOpen(false)}
				open={isNotificationDialogOpen}
			/>
			<EstablishmentAppBar />
			<Box padding="20px">
				{bagState.isEmpty ? (
					<EmptyBag />
				) : (
					<div>
						{submitStatus !== 'submitted' && (
							<Typography variant="h5">Resumo do pedido</Typography>
						)}
						{submitStatus === 'submitted' && (
							<SuccessCard message="Seu pedido foi enviado com sucesso." />
						)}
						<Products
							canRemove={
								submitStatus !== 'submitted' && !establishment.isDelivery
							}
						/>
						{submitStatus !== 'submitted' && <AddMoreItemsButton />}
						<Trailer />
						{establishment.isDelivery && (
							<>
								<DeliveryType
									value={bagState.deliveryMethod!}
									address={bagState.deliveryAddress}
								/>
								<PaymentMethod value={bagState.paymentMethod!} />
								<Customer />
							</>
						)}
						{submitStatus === 'submitted' && (
							<>
								<OrderCreatedInfo
									customer={
										establishment.isSelfService
											? { name: name.value }
											: undefined
									}
								/>
								{establishment.isDelivery && establishment.phone && (
									<Box display="flex" justifyContent="center">
										<Button
											variant="outlined"
											onClick={openWhatsApp}
											style={{ textTransform: 'unset' }}
											endIcon={<WhatsAppIcon />}
										>
											Precisa falar com o estabelecimento?
										</Button>
									</Box>
								)}
								<PrimaryButton label="Fazer novo pedido" onClick={goBack} />
							</>
						)}
						{submitStatus !== 'submitted' && (
							<>
								{establishment.isSelfService && (
									<NameInput
										{...name}
										onChange={onNameChanged}
										style={{ marginTop: '20px' }}
									/>
								)}
								{!establishment.isMenu && (
									<PrimaryButton
										label="Fazer pedido"
										onClick={_sendOrder}
										isLoading={submitStatus === 'submitting'}
									/>
								)}
							</>
						)}
					</div>
				)}
				<ErrorDialog
					error={error}
					open={submitStatus === 'error'}
					close={() => setSubmitStatus('idle')}
				/>
			</Box>
		</>
	);
}
