import { AppBar, Box, Typography } from '@mui/material';
// import { PieChart } from '@mui/x-charts/PieChart/';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { Pie, PieChart } from 'recharts';
import pizzaImg from '../../../shared/assets/images/pizza.png';
import { usePizza } from '../PizzaContext';
import { colors } from '../flavor_colors';

export interface PizzaChartHandle {
	scrollToPizzaChart: () => void;
}

type PieChartData = {
	value: number;
	label: string;
	fill: string;
};

export const PizzaChart = forwardRef<PizzaChartHandle, {}>((props, ref) => {
	const { size, additionals } = usePizza().state;
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const { selectedAdditionals, getGroupById } = usePizza();
	const [data, setData] = useState<PieChartData[]>([]);
	const appBarRef = useRef<HTMLDivElement>(null);

	useImperativeHandle(ref, () => ({
		scrollToPizzaChart: () => {
			if (!appBarRef.current) return;
			const position =
				appBarRef.current.getBoundingClientRect().top + window.scrollY;
			window.scrollTo({
				top: position - 65, // desconta a altura do AppBar (65px)
				behavior: 'smooth',
			});
		},
	}));

	useEffect(() => {
		const handleResize = () => setWindowHeight(window.innerHeight);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (size == null) {
			return setData([]);
		}
		const additionals = selectedAdditionals();
		const data: PieChartData[] = [];
		let emptySlices = size!.flavorsQtt;
		const slice = 100 / size!.flavorsQtt;
		for (let i = 0; i < additionals.length; i++) {
			const { name, quantity, groupId } = additionals[i];
			const group = getGroupById(groupId);
			const color = colors[i % colors.length] + '80';
			let label = '';
			if (group.isFlavor) {
				label = `${quantity}/${size!.flavorsQtt} ${name}`;
			} else if (group.isCrust) {
				label = `Borda: ${name}`;
			} else {
				label = `${quantity}x ${name}`;
			}
			const value = group.isFlavor ? slice * quantity : 0;
			if (group.isFlavor) {
				emptySlices -= quantity;
			}
			data.push({ label, value, fill: color });
		}
		for (let i = 0; i < emptySlices; i++) {
			const color = '#ffffff00';
			data.push({ label: '', value: slice, fill: color });
		}
		setData(data);
	}, [size, additionals]);

	function getLegends() {
		return data
			.filter((item) => !!item.label)
			.map(({ label, fill }) => ({ label, fill }));
	}

	function getChartSize() {
		let scaleFactor = 1;
		if (windowHeight > 900) {
			scaleFactor = 1.5;
		} else if (windowHeight < 700) {
			scaleFactor = 0.8;
		}
		return {
			chartSize: 93 * scaleFactor,
			outerRadius: 41 * scaleFactor,
			chartMarginLeft: 7 * scaleFactor,
			chartTop: 5 * scaleFactor,
			imgWidth: 100 * scaleFactor,
		};
	}

	return (
		<AppBar
			ref={appBarRef}
			position="sticky"
			sx={{
				top: '65px',
			}}
		>
			<Box bgcolor="grey.100" padding="15px" borderRadius="10px">
				<Typography variant="h6">Minha pizza</Typography>
			</Box>
			<Box padding="12px 0 22px 0" display="flex" alignItems="start">
				<Box position="relative">
					<PieChart
						width={getChartSize().chartSize}
						height={getChartSize().chartSize}
						margin={{ left: getChartSize().chartMarginLeft }}
						style={{
							top: getChartSize().chartTop,
						}}
					>
						<Pie
							dataKey="value"
							nameKey="label"
							isAnimationActive={true}
							animationBegin={0}
							animationDuration={500}
							data={data}
							outerRadius={getChartSize().outerRadius}
							stroke="#000"
							strokeWidth={size?.flavorsQtt === 1 ? 0 : 1.5}
						/>
					</PieChart>
					<img
						src={pizzaImg}
						width={getChartSize().imgWidth}
						alt=""
						style={{
							position: 'absolute',
							top: '0px',
							left: '0',
							zIndex: -1,
						}}
					/>
				</Box>
				<Box
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					marginLeft="24px"
				>
					{getLegends().map((item) => (
						<Box
							key={item.label}
							display="flex"
							flexDirection="row"
							justifyContent="center"
							alignItems="center"
							marginRight="15px"
							marginTop="10px"
						>
							<Box
								width="16px"
								height="16px"
								borderRadius="50%"
								bgcolor={item.fill}
								marginRight="5px"
							/>
							<Typography variant="caption">{item.label}</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</AppBar>
	);
});
