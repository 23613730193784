import { DeliveryMethod, OrderItem, PaymentMethod } from '../entities';
import { DeliveryAddress } from '../entities/delivery_address';

export class BagRepository {
	async saveBag(bag: Bag): Promise<void> {
		sessionStorage.setItem(
			'bag',
			JSON.stringify({
				items: bag.items,
				deliveryMethod: bag.deliveryMethod,
				pendingDeliveryAddresses: bag.pendingDeliveryAddresses?.map((a) =>
					a.toJson()
				),
				deliveryAddress: bag.deliveryAddress?.toJson(),
				paymentMethod: bag.paymentMethod,
				changeFor: bag.changeFor,
			})
		);
	}

	async getBag(): Promise<Bag | null> {
		try {
			const bagStr = sessionStorage.getItem('bag');
			if (!bagStr) return null;
			const bagJson = JSON.parse(bagStr);
			return {
				items: bagJson.items.map(OrderItem.fromJson),
				deliveryMethod: bagJson.deliveryMethod,
				pendingDeliveryAddresses:
					bagJson.pendingDeliveryAddresses?.map(DeliveryAddress.fromJson) ?? [],
				deliveryAddress: bagJson.deliveryAddress
					? DeliveryAddress.fromJson(bagJson.deliveryAddress)
					: undefined,
				paymentMethod: bagJson.paymentMethod,
				changeFor: bagJson.changeFor,
				customerDocument: bagJson.customerDocument,
			};
		} catch (error) {
			sessionStorage.removeItem('bag');
			return null;
		}
	}
}

type Bag = {
	items: OrderItem[];
	deliveryMethod: DeliveryMethod | undefined;
	pendingDeliveryAddresses: DeliveryAddress[];
	deliveryAddress: DeliveryAddress | undefined;
	paymentMethod: PaymentMethod | undefined;
	changeFor: number;
	customerDocument: string;
};
