import {
	Box,
	ListItem,
	ListItemButton,
	Radio,
	Typography,
} from '@mui/material';
import { PizzaSize } from '../../../shared/entities';
import { usePizza } from '../PizzaContext';

export function PizzaSizes() {
	const { pizza, isLoadingProduct } = usePizza().state;
	const { selectSize } = usePizza();
	return (
		<>
			<Box
				bgcolor="grey.100"
				padding="15px"
				borderRadius="10px"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="12px"
			>
				<Typography variant="h6">Tamanho</Typography>
				<RequiredLabel />
			</Box>
			{pizza!.sizes.map((size) => (
				<ListItem
					key={size.id}
					onClick={isLoadingProduct ? undefined : () => selectSize(size)}
					secondaryAction={<RadioSize size={size} />}
					disablePadding
				>
					<ListItemButton
						sx={{
							'&:hover': { backgroundColor: 'transparent' },
						}}
						disableTouchRipple
					>
						<div>
							<Typography variant="subtitle2" paddingRight={12}>
								{size.size}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								paddingRight={12}
							>
								{size.flavorsQtt === 1
									? '1 sabor'
									: `${size.flavorsQtt} sabores`}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								paddingRight={12}
							>
								{size.description}
							</Typography>
						</div>
					</ListItemButton>
				</ListItem>
			))}
		</>
	);
}

function RadioSize({ size }: { size: PizzaSize }) {
	const checked = usePizza().state.size === size;
	return <Radio value={size} checked={checked} />;
}

function RequiredLabel() {
	return (
		<Box
			sx={{
				backgroundColor: 'grey.900',
				borderRadius: '4px',
				padding: '1px 8px',
			}}
		>
			<Typography variant="subtitle2" style={{ color: 'white' }}>
				Obrigatório
			</Typography>
		</Box>
	);
}
