import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useBag } from '../../../shared/contexts';
import { OrderItem } from '../../../shared/entities';

type Props = {
	item: OrderItem;
	index: number;
	isLast: boolean;
	canRemove: boolean;
};

export const ProductTile = ({ item, index, isLast, canRemove }: Props) => {
	const [open, setOpen] = useState(false);

	return (
		<Box marginBottom={isLast ? 0 : '10px'}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Box display="flex" alignItems="center" marginRight="12px">
					<Typography variant="subtitle2" marginRight="5px" fontWeight="bold">
						{item.quantity}x
					</Typography>
					<Typography variant="subtitle2" fontWeight="bold" align="left">
						{item.name}
					</Typography>
				</Box>
				<Box display="flex" alignItems="center">
					<Typography variant="subtitle2" fontWeight="bold" marginRight="5px">
						{(item.price * item.quantity).toCurrency()}
					</Typography>
					{canRemove && (
						<IconButton onClick={() => setOpen(true)}>
							<RemoveOutlinedIcon color="error" />
						</IconButton>
					)}
				</Box>
			</Box>
			<Box padding={!canRemove ? '0 5px 0 20px' : '0 45px 0 22px'}>
				{item.additionals.map((additional) => {
					return (
						<Box
							display="flex"
							key={additional.id}
							justifyContent="space-between"
						>
							<Typography variant="body2" fontWeight="600">
								{additional.quantity} - {additional.name}
							</Typography>
							<Typography variant="body2" fontWeight="600">
								{additional.total.toCurrency()}
							</Typography>
						</Box>
					);
				})}
				{item.observations && (
					<Typography variant="body2" align="left" marginTop="3px">
						Obs: {item.observations}
					</Typography>
				)}
			</Box>
			<RemoveProductDialog
				open={open}
				close={() => setOpen(false)}
				index={index}
			/>
		</Box>
	);
};

function RemoveProductDialog({
	open,
	close,
	index,
}: {
	open: boolean;
	close: (open: boolean) => void;
	index: number;
}) {
	const { removeItem } = useBag();

	const handleClose = (remove: boolean) => {
		close(false);
		if (remove) {
			removeItem(index);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Deseja remover este produto?</DialogTitle>
			<DialogActions>
				<Button onClick={() => handleClose(false)}>Não</Button>
				<Button onClick={() => handleClose(true)}>Sim</Button>
			</DialogActions>
		</Dialog>
	);
}
