import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	AppError,
	EstablishmentNotFoundError,
	SessionExpiredError,
} from '../../shared/errors';
import { removeUrlParameter } from '../../shared/router_helpers';
import { EstablishmentOffline, ReloadScreen } from '../components';
import { BlockedCustomer } from '../components/BlockedCustomer';
import { Loading } from '../components/Loading';
import { CustomerStatus, Establishment } from '../entities';
import { Config } from '../entities/config';
import {
	CustomerRepository,
	EstablishmentRepository,
	SessionRepository,
} from '../repositories';
import { useUser } from './UserContext';

type EstablishmentType = {
	state: {
		establishment: Establishment;
		mainError?: AppError | null;
		isOnline: boolean;
		establishmentClosed: boolean;
		blockedCustomer: boolean;
		config: Config;
		isLoading: boolean;
	};
	setSessionAsExpired: () => void;
};

const DEFAULT_VALUE = {
	state: {
		establishment: undefined as any,
		mainError: null as AppError | null,
		isOnline: true,
		establishmentClosed: false,
		blockedCustomer: false,
		config: null as any,
		isLoading: true,
	},
	setSessionAsExpired: () => {},
};

const EstablishmentContext =
	React.createContext<EstablishmentType>(DEFAULT_VALUE);

export const useEstablishment = (): EstablishmentType =>
	useContext(EstablishmentContext);

type Props = {
	sessionRepository: SessionRepository;
	establishmentRepository: EstablishmentRepository;
	customerRepository: CustomerRepository;
	children: ReactNode;
};

export const EstablishmentContextProvider = ({
	children,
	sessionRepository,
	establishmentRepository,
	customerRepository,
}: Props) => {
	const location = useLocation();
	const { isLogged } = useUser().state;
	const [state, setState] = useState(DEFAULT_VALUE.state);

	const init = async () => {
		try {
			setState({ ...state, isLoading: true });
			const tokenUrl = new URLSearchParams(location.search).get('token');
			if (tokenUrl) {
				removeUrlParameter(location, 'token');
				sessionRepository.startSession(tokenUrl);
			}
			const session = sessionRepository.getSession();
			if (!session) throw new Error();
			if (session.isExpired) {
				sessionRepository.clearSession();
				return setState({
					...state,
					mainError: new SessionExpiredError(),
					isLoading: false,
				});
			}
			const isOnline = await establishmentRepository.isOnline();
			if (!isOnline) {
				return setState({ ...state, isOnline: false, isLoading: false });
			}
			if (session.establishment.isDelivery && isLogged) {
				const customerStatus = await customerRepository.getStatus();
				if (customerStatus === CustomerStatus.inactive) {
					return setState({
						...state,
						establishment: session.establishment,
						blockedCustomer: true,
						isLoading: false,
					});
				}
			}

			const config = await establishmentRepository.getConfig();

			let isOpened = true;
			if (session.establishment.isDelivery) {
				isOpened = await establishmentRepository.isOpened();
			}

			setState({
				...state,
				establishment: session.establishment,
				config,
				establishmentClosed: !isOpened,
				mainError: null,
				isLoading: false,
			});
		} catch (error) {
			sessionRepository.clearSession();
			setState({
				...state,
				establishment: null,
				mainError: new EstablishmentNotFoundError(),
				isLoading: false,
			});
		}
	};

	useEffect(() => {
		init();
	}, []);

	const setSessionAsExpired = () => {
		setState({
			...state,
			establishment: null,
			mainError: new SessionExpiredError(),
		});
	};

	if (state.isLoading) {
		return <Loading />;
	}

	if (!state.isOnline) {
		return <EstablishmentOffline />;
	}

	if (state.establishment) {
		return (
			<EstablishmentContext.Provider value={{ state, setSessionAsExpired }}>
				{state.blockedCustomer ? <BlockedCustomer /> : children}
			</EstablishmentContext.Provider>
		);
	}

	return <ReloadScreen message={state.mainError?.message ?? ''} />;
};
