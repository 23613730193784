export class Group {
	constructor(
		public id: number,
		public name: string,
		public isPizza: boolean,
		public subgroups: Group[]
	) {}

	static fromJson(
		json: any,
		{ isSubgroup }: { isSubgroup: boolean } = { isSubgroup: false }
	): Group {
		const subGroups: Group[] = [];
		if (!isSubgroup) {
			for (const subgroupLevel1 of json.subgroups ?? []) {
				subGroups.push(Group.fromJson(subgroupLevel1, { isSubgroup: true }));
				for (const subgroupLevel2 of subgroupLevel1.subgroups ?? []) {
					subGroups.push(Group.fromJson(subgroupLevel2, { isSubgroup: true }));
				}
			}
		}
		return new Group(json.id, json.name, json.isPizza || false, subGroups);
	}

	get hasSubgroups(): boolean {
		return this.subgroups.length > 0;
	}

	get archorLink(): string {
		return this.name.removeDiacritics().toLowerCase().replace(/ /g, '-');
	}
}
