import { Group, Pizza, Product } from '../entities';
import { SessionExpiredError } from '../errors';
import { HttpClient } from '../http_client';
import { BaseRepository } from './base_repository';

export class ProductRepository extends BaseRepository {
	constructor(private httpClient: HttpClient) {
		super();
	}

	async getGroups(): Promise<Group[]> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/groups`;
		const json = await this.httpClient.get(path);
		return json.map((item: any) => Group.fromJson(item));
	}

	async getProducts(input: GetProductsInput): Promise<Product[]> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		let path = `/establishments/${session.establishment.uuid}/menu`;
		path += `?page=${input.page}&size=${input.size}`;
		if (input.groupId !== undefined) {
			path += `&groupId=${input.groupId}`;
		}
		if (input.text) {
			path += `&search=${input.text}`;
		}
		const json = await this.httpClient.get(path);
		return json.map((item: any) => Product.fromJson(item));
	}

	async getProduct(id: number): Promise<Product> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/products/${id}`;
		const json = await this.httpClient.get(path);
		return Product.fromJson(json);
	}

	async getPizza(): Promise<Pizza> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/menu/pizzas`;
		const json = await this.httpClient.get(path);
		return Pizza.fromJson(json);
	}
}

type GetProductsInput = {
	page: number;
	size: number;
	groupId?: number;
	text?: string;
};
