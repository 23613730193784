import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { DeliveryMethod, OrderItem, PaymentMethod } from '../entities';
import { DeliveryAddress } from '../entities/delivery_address';
import { BagRepository } from '../repositories';

type Props = {
	bagRepository: BagRepository;
	children: ReactNode;
};

type PropsBagContext = {
	state: {
		items: OrderItem[];
		subtotal: number;
		total: number;
		isEmpty: boolean;
		totalTip: number;
		deliveryMethod?: DeliveryMethod;
		deliveryAddress?: DeliveryAddress;
		deliveryFee?: number;
		minOrderValue: number;
		paymentMethod?: PaymentMethod;
		changeFor: number;
		pendingDeliveryAddresses: DeliveryAddress[];
		customerDocument: string;
	};
	addItem: (item: OrderItem) => void;
	removeItem: (itemIndex: number) => void;
	setDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
	setDeliveryAddress: (
		deliveryAddress: DeliveryAddress,
		deliveryFee: number,
		minOrderValue: number
	) => void;
	addAndSetDeliveryAddress: (
		deliveryAddress: DeliveryAddress,
		deliveryFee: number,
		minOrderValue: number
	) => void;
	unsetDeliveryAddress: () => void;
	setPaymentMethod: (paymentMethod: PaymentMethod) => void;
	setChangeFor: (changeFor: number) => void;
	clearBag: () => void;
	addDeliveryAddress: (deliveryAddress: DeliveryAddress) => void;
	removeDeliveryAddress: (index: number) => void;
	updateCustomerDocument: (document: string) => void;
};

const DEFAULT_VALUE = {
	state: {
		items: [] as OrderItem[],
		subtotal: 0,
		total: 0,
		isEmpty: true,
		totalTip: 0,
		deliveryMethod: undefined as DeliveryMethod | undefined,
		deliveryAddress: undefined as DeliveryAddress | undefined,
		deliveryFee: undefined as number | undefined,
		minOrderValue: 0,
		paymentMethod: undefined as PaymentMethod | undefined,
		changeFor: 0,
		pendingDeliveryAddresses: [] as DeliveryAddress[],
		customerDocument: '',
	},
	addItem: () => {},
	removeItem: () => {},
	setDeliveryMethod: () => {},
	setDeliveryAddress: () => {},
	unsetDeliveryAddress: () => {},
	setPaymentMethod: () => {},
	setChangeFor: () => {},
	clearBag: () => {},
	addDeliveryAddress: () => {},
	removeDeliveryAddress: () => {},
	addAndSetDeliveryAddress: () => {},
	updateCustomerDocument: () => {},
};

export const BagContext = createContext<PropsBagContext>(DEFAULT_VALUE);

export const useBag = (): PropsBagContext => useContext(BagContext);

export const BagContextProvider = ({ children, bagRepository }: Props) => {
	const [state, setState] = useState(DEFAULT_VALUE.state);

	useEffect(() => {
		bagRepository.getBag().then((bag) => {
			if (bag) {
				setState({
					...state,
					items: bag.items,
					deliveryMethod: bag.deliveryMethod,
					pendingDeliveryAddresses: bag.pendingDeliveryAddresses,
					deliveryAddress: bag.deliveryAddress,
					paymentMethod: bag.paymentMethod,
					changeFor: bag.changeFor,
					customerDocument: bag.customerDocument,
				});
			}
		});
	}, []);

	useEffect(() => {
		const totalTip = state.items.reduce((acc, product) => acc + product.tip, 0);
		const subtotal = state.items.reduce((acc, item) => acc + item.total, 0);
		const total = subtotal + totalTip + (state.deliveryFee ?? 0);
		const isEmpty = state.items.length === 0;

		setState({
			...state,
			total,
			subtotal,
			totalTip,
			isEmpty,
		});
	}, [state.items, state.deliveryFee]);

	useEffect(() => {
		bagRepository.saveBag({
			items: state.items,
			deliveryMethod: state.deliveryMethod,
			pendingDeliveryAddresses: state.pendingDeliveryAddresses,
			deliveryAddress: state.deliveryAddress,
			paymentMethod: state.paymentMethod,
			changeFor: state.changeFor,
			customerDocument: state.customerDocument,
		});
	}, [
		state.items,
		state.deliveryMethod,
		state.pendingDeliveryAddresses,
		state.deliveryAddress,
		state.paymentMethod,
		state.changeFor,
		state.customerDocument,
	]);

	function addItem(item: OrderItem): void {
		const items = [...state.items, item];
		setState({ ...state, items });
	}

	function removeItem(itemIndex: number): void {
		const items = state.items.filter((item, index) => index !== itemIndex);
		setState({ ...state, items });
	}

	function setDeliveryMethod(deliveryMethod: DeliveryMethod): void {
		setState({
			...state,
			deliveryMethod,
			deliveryAddress: undefined,
			deliveryFee: 0,
		});
	}

	function setDeliveryAddress(
		deliveryAddress: DeliveryAddress,
		deliveryFee: number,
		minOrderValue: number
	): void {
		setState({
			...state,
			deliveryAddress,
			deliveryFee: deliveryFee,
			minOrderValue: minOrderValue,
		});
	}

	function unsetDeliveryAddress(): void {
		setState({
			...state,
			deliveryAddress: undefined,
			deliveryFee: 0,
			minOrderValue: 0,
		});
	}

	function addAndSetDeliveryAddress(
		deliveryAddress: DeliveryAddress,
		deliveryFee: number,
		minOrderValue: number
	): void {
		const pendingDeliveryAddresses = [
			...state.pendingDeliveryAddresses,
			deliveryAddress,
		];
		setState({
			...state,
			deliveryAddress,
			deliveryFee,
			minOrderValue,
			pendingDeliveryAddresses,
		});
	}

	function setPaymentMethod(paymentMethod: PaymentMethod): void {
		setState({ ...state, paymentMethod, changeFor: 0 });
	}

	function setChangeFor(changeFor: number): void {
		setState({ ...state, changeFor });
	}

	function addDeliveryAddress(deliveryAddress: DeliveryAddress): void {
		const pendingDeliveryAddresses = [
			...state.pendingDeliveryAddresses,
			deliveryAddress,
		];
		setState({
			...state,
			pendingDeliveryAddresses,
		});
	}

	function removeDeliveryAddress(index: number): void {
		const pendingDeliveryAddresses = state.pendingDeliveryAddresses.filter(
			(_, i) => i !== index
		);
		setState({
			...state,
			pendingDeliveryAddresses,
		});
	}

	function updateCustomerDocument(document: string): void {
		setState({ ...state, customerDocument: document });
	}

	const clearBag = (): void => setState(DEFAULT_VALUE.state);

	return (
		<BagContext.Provider
			value={{
				state,
				addItem,
				removeItem,
				setDeliveryMethod,
				setDeliveryAddress,
				setPaymentMethod,
				setChangeFor,
				clearBag,
				addDeliveryAddress,
				removeDeliveryAddress,
				addAndSetDeliveryAddress,
				unsetDeliveryAddress,
				updateCustomerDocument,
			}}
		>
			{children}
		</BagContext.Provider>
	);
};
