import { DeliveryAddress } from './delivery_address';
import { DeliveryMethod } from './delivery_method';
import { PaymentMethod } from './payment_method';

export type Order = {
	customer?: OrderCustomer;
	type: OrderType;
	items: OrderItem[];
	valueForChange: number;
	deliveryMethod: DeliveryMethod | undefined;
	paymentMethod: PaymentMethod | undefined;
	deliveryAddress: DeliveryAddress | undefined;
	deliveryFee: number;
};

type OrderType = 'delivery' | 'table';

type OrderCustomer = {
	name?: string;
	document?: string;
	deliveryAddresses: DeliveryAddress[];
};

export class OrderItem {
	public tip: number;

	constructor(
		public id: number,
		public name: string,
		public price: number,
		public quantity: number,
		public observations: string,
		public tipPercent: number,
		public additionals: OrderItemAdditional[],
		public total: number,
		public isPizza: boolean = false,
		public pizzaSizeId?: number
	) {
		this.tip = this.total * (this.tipPercent / 100);
	}

	static fromJson(json: any): OrderItem {
		return new OrderItem(
			json.id,
			json.name,
			json.price,
			json.quantity,
			json.observations,
			json.tipPercent,
			json.additionals.map(OrderItemAdditional.fromJson),
			json.total,
			json.isPizza || false,
			json.pizzaSizeId
		);
	}
}

export class OrderItemAdditional {
	constructor(
		public id: number,
		public groupId: number,
		public name: string,
		public price: number,
		public quantity: number,
		public total: number,
		public position: number,
		public sizeFlavorId?: number
	) {}

	static fromJson(json: any): OrderItemAdditional {
		return new OrderItemAdditional(
			json.id,
			json.groupId,
			json.name,
			json.price,
			json.quantity,
			json.total,
			json.position,
			json.sizeFlavorId
		);
	}
}
