import {
	BlockedCustomerError,
	EstablishmentClosedError,
	SessionExpiredError,
	UnexpectedError,
} from '../../shared/errors';
import { Order } from '../entities';
import { HttpBadRequestError, HttpClient } from '../http_client';
import { BaseRepository } from './base_repository';

export class OrderRepository extends BaseRepository {
	constructor(private httpClient: HttpClient) {
		super();
	}

	async sendOrder(order: Order): Promise<void> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		try {
			const path = `/establishments/${session.establishment.uuid}/order`;
			await this.httpClient.post(path, {
				type: order.type,
				customer: {
					name: order.customer?.name,
					document: order.customer?.document,
					deliveryAddresses: order.customer?.deliveryAddresses.map((address) =>
						address.toJson()
					),
				},
				table: order.type === 'table' ? session.establishment.table : undefined,
				delivery:
					order.type === 'delivery'
						? {
								valueForChange: order.valueForChange,
								deliveryMethod: order.deliveryMethod,
								paymentMethod: order.paymentMethod,
								deliveryAddress: order.deliveryAddress?.toJson(),
								deliveryFee: order.deliveryFee,
						  }
						: undefined,
				items: order.items,
			});
		} catch (error) {
			if (error instanceof HttpBadRequestError) {
				if (error.name === 'EstablishmentClosed') {
					throw new EstablishmentClosedError();
				}
				if (error.name === 'CustomerInactive') {
					throw new BlockedCustomerError();
				}
			}
			throw new UnexpectedError();
		}
	}
}
