import { Box } from '@mui/material';
import { usePizza } from '../PizzaContext';
import { AdditionalGroupHeader } from './AdditionalGroupHeader';
import { AdditionalTile } from './AdditionalTile';

export function Additionals() {
	const { product } = usePizza().state;

	const list = product!.additionalGroups.map((group) => (
		<Box key={`group:${group.id}`}>
			<AdditionalGroupHeader group={group} />
			{group.additionals.map((additional) => (
				<AdditionalTile
					key={`additional:${additional.id}`}
					group={group}
					additional={additional}
				/>
			))}
		</Box>
	));

	return <>{list}</>;
}
